export default [{
    title: 'Übersicht',
    route: 'dashboard',
    icon: 'BarChartIcon',
    action: 'manage'
  },
  {
    title: 'Kunde',
    route: 'customers-list',
    icon: 'UserIcon',
    action: 'read'
  },
  {
    title: 'Behandlung',
    route: 'treatments-list',
    icon: 'FileTextIcon',
    action: 'read'
  },
  {
    title: 'Mitarbeiter',
    route: 'employees-list',
    icon: 'SmileIcon',
    action: 'manage'
  },
  {
    title: 'Neuer Kunde',
    route: 'contract',
    icon: 'UserPlusIcon',
    action: 'read'
  },
]